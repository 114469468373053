<template>

    <div class="main-body">
        <div class="order-success-wrapper">
            <div class="order-success-box">
                <p class="order-failed-text">Order Failed</p>
                <h1 class="order-title">Order Failed</h1>
                <p class="order-description">The transaction could not be processed. Please try again later.</p>

                <div class="order-items">
                    <p class="order-failed-text" style="color: #3a3a3a;">Items in Cart</p>
                    <ul>
                        <li class="mc-item" v-for="item in orderItems" :key="item">
                            <div>
                                <img class="mc-image" :src="'https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F' + item.id +'%2F1.jpg?alt=media'" alt="Cart Image">
                            </div>
                            <div class="mc-product-details">
                                <div class="mc-name-price-feat-box">
                                    <div class="mc-name-price-box">
                                        <a :href="'/product/'+item.id"><h3 class="mc-product-name">{{item.name}}</h3></a>
                                        <p class="mc-product-price">${{getItemSubtotal(item.price, item.quantity)}}</p>
                                    </div>
                                    <p class="mc-product-feature">{{item.size}}, {{item.color}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="mc-total-box">
                    <p>Subtotal</p>
                    <p>${{subTotal}}</p>
                </div>

                <p class="continue-shopping-btn"><a href="/">Continue Shopping ➜</a></p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'OrderFailed',
    data(){
        return{
            orderItems: [],
            subTotal: 0.00
        }
    },
    mounted(){
        this.getOrderItems();
    },
    methods:{
        // Get order details from firestore
        getOrderItems(){
            var orderItems = JSON.parse(localStorage.getItem("cartItems"));
            if(orderItems != null){
                this.orderItems = orderItems;
                this.calculatePrice();
            }
        },
        // Get Subtotal of each Item
        getItemSubtotal(price, quantity){
            return price*quantity;
        },
        /* Calculate Final Price of all Items */
		calculatePrice(){
			this.subTotal = 0.0;
			for(let i=0;i<this.orderItems.length;++i){
				this.subTotal = this.subTotal + (parseFloat(this.orderItems[i]["price"]) * parseInt(this.orderItems[i]["quantity"]))
			}
		}
    }
}
</script>

<style scoped>
    .main-body{max-width: 1280px;margin: auto;}
    .order-success-wrapper{position: relative;padding: 0 12px;width: 100%;;margin: 80px 0px;}
    .order-success-box{max-width: 550px;margin: auto;}

    .order-failed-text{color: #de3c3c;font-size: 13px;font-weight: 600;cursor: pointer;text-transform: uppercase;}
    .order-title{margin: 6px 0px;}
    .order-description{font-size: 15px;line-height: 1.4;font-weight: 400;color: #868686;}

    .order-items{margin: 48px 0px 16px 0px;}
    .mc-item{padding: 12px 0px;display: flex;}
    .mc-image{height: 90px;object-fit: cover;object-position: center;border-radius: 6px;}
    .mc-product{display: grid;grid-template-columns: 80px auto;width: 100%;grid-gap: 12px;margin-bottom: 12px;padding-bottom: 12px;border-bottom: 1px solid #e9e9e9;}
    .mc-product-details{flex-direction: column;flex: 1 1 0%;display: flex;margin-left: 16px;}
    .mc-name-price-feat-box{position: relative;}
    .mc-name-price-box{line-height: 24px;display: flex;justify-content: space-between;}
    .mc-product-name{font-size: 16px;font-weight: 500;}
    .mc-product-price{margin-left: 16px;font-size: 15px;}
    .mc-product-feature{color: #6b7280;font-size: 14px;line-height: 20px;margin-top: 4px;}

    .mc-subtotal-box, .mc-total-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;font-weight: 500;padding: 10px 0px;}
    .mc-total-box{border-top: 1px solid #e5e7eb;padding-top: 16px;margin-top: 8px;}
    .subtotal-header{color: #6b7280;font-size: 14px;line-height: 20px;font-weight: 400;}
    .subtotal-value{font-size: 14px;line-height: 20px;}
    .mc-header{text-align: left;line-height: 1.5;font-size: 18px;font-weight: 500;margin: 0;margin-bottom: 20px;}
    .continue-shopping-btn{color: #6366f1;font-size: 14px;font-weight: 600;cursor: pointer;margin-top: 32px;user-select: none;}
    .continue-shopping-btn:hover{text-decoration: underline;}
</style>
